import React from "react"

import Layout from "../components/layout"
import Page from "../components/Page/page"
import GoogleMaps from "../components/GoogleMaps"
import ContactForm from "../components/Forms/contact"

class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showInfoWindow: true
    }
  }

  toggleInfoWindow = () => this.setState({showInfoWindow: !this.state.showInfoWindow })

  render() {
    const { location } = this.props
    return (
      <Layout location={location}>
        <Page id="3" />
        <GoogleMaps 
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `500px`, marginBottom: `2rem`  }} />}
          mapElement={<div style={{ height: `100%` }} />}
          mapCenter={{ lat: -35.07987, lng: 150.6451333 }}
          isMarkerShown
          markerCenter={{ lat: -35.07987, lng: 150.64734 }}
          zoom={14}
          toggleInfoWindow={this.toggleInfoWindow}
          showInfoWindow={this.state.showInfoWindow}
          infoWindowData={
              <>
              <h5 className="googlemaps-heading">C3 Church Jervis Bay</h5>
              <p className="googlemaps-text">20 Birriga Ave<br/>
              Worrowing Heights, NSW, 2540</p>
              </>
          }
        />
        <ContactForm />
      </Layout>
    )
  }
}

export default Contact
