import React from "react"
import Icon from "../Icon"

import "./styles.scss"

const Button = ({ disabled, btnStyle, customClass, type, children, icon, iconPos  }) => {
    if(disabled === true) {
        return (
            <button className={`btn btn-${btnStyle} ${customClass}`} type={type} disabled>{`${children} `}<Icon name="spinner" spin={true}/></button>
        )
    } else if (iconPos === "left") {
        return (
            <button className={`btn btn-${btnStyle} ${customClass}`} type={type}>{icon ? <Icon name={icon} /> : ""}{`${children} `}</button>
        )
    } else {
        return (
            <button className={`btn btn-${btnStyle} ${customClass}`} type={type}>{`${children} `} {icon ? <Icon name={icon} /> : ""}</button>
        )
    }
}

export default Button