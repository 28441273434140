import React from "react"
import axios from "axios"
import Recaptcha from "react-recaptcha"

import Message from "../Message"
import Feedback from "../Forms/Feedback"
import Button from "../Button"

import "./contact.scss"

const formsAPI = process.env.API_FORMS
let recaptchaInstance;

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            message: {},
            canSubmit: true
        }
    }

    validateInputs = () => {
        let ok = true;
        this.setState({errors: {}})
        if (!this.state.name) {
            this.setState(({errors}) => ({errors: {
            ...errors,
            name: "Please enter a valid name",
            }}))
            ok = false;
        }
        if (!this.state.reCaptcha) {
            this.setState(({errors}) => ({errors: {
            ...errors,
            reCaptcha: "Please verity you are a human",
            }}))
            ok = false;
        }
        if (!this.isEmail(this.state.email)) {
            this.setState(({errors}) => ({errors: {
            ...errors,
            email: "Please enter a valid e-mail",
            }}))
            ok = false;
        }
        return ok;
    }

    isEmail = e => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(e);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.validateInputs() === false) {
            return
        } else {
            this.setState({
                canSubmit: false,
                message: {}
            })
            axios.post(`${formsAPI}/contact`,
            {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                enquiry: this.state.enquiry,
                reCaptcha: this.state.reCaptcha,
            })
            .then(() => {
                this.setState({
                    canSubmit: true,
                    message: {
                        text: "Message Sent",
                        type: "success"
                    }
                })
                this.resetRecaptcha()
            })
            .catch(error => {
                this.setState({
                    canSubmit: true
                })
                if (error.response) {
                    this.setState({
                        message: {
                            text: error.response.data.message,
                            type: "danger"
                        }
                    })
                    this.resetRecaptcha()
                } else if (error.request) {
                    this.setState({
                        message: {
                            text: "Server Error",
                            type: "danger"
                        }
                    })
                    this.resetRecaptcha()
                }
            })
        }
    }

    handleChange = e => this.setState({ [e.target.name]: e.target.value })

    verifyReCaptcha = response => this.setState({"reCaptcha": response})

    resetRecaptcha = () => recaptchaInstance.reset()

    render() {
        const { errors, message, canSubmit } = this.state
        return(
            <>
            <form onSubmit={this.handleSubmit}>
                <div className="row justify-content-center">
                    <div className="col-lg-4 form-col-1">
                        <div className="form-group">
                            <input onChange={this.handleChange} name="name" className={errors.name ? "form-control is-invalid" : "form-control"} id="name" aria-describedby="nameHelp" placeholder="Name" />
                            <Feedback message={errors.name} />
                        </div>
                        <div className="form-group">
                            <input onChange={this.handleChange} name="email" className={errors.email ? "form-control is-invalid" : "form-control"}  id="email" aria-describedby="emailHelp" placeholder="E-Mail" />
                            <Feedback message={errors.email} />
                        </div>
                        <div className="form-group">
                            <input onChange={this.handleChange} name="phone" className="form-control" id="phone" aria-describedby="phoneHelp" placeholder="Phone" />
                        </div>
                    </div>
                    <div className="col-lg-4 form-col-2">
                        <div className="form-group">
                            <textarea onChange={this.handleChange} name="enquiry" className="form-control" id="enquiry" rows="6" placeholder="Message"></textarea>
                        </div>
                        <div className="form-group">
                            <Recaptcha
                                ref={e => recaptchaInstance = e}
                                sitekey={process.env.GOOGLE_RECAPTCHA_KEY}
                                verifyCallback={this.verifyReCaptcha}
                            />
                            <Feedback message={errors.reCaptcha} />
                        </div>
                        <div className="form-group">
                            <Message type={message.type} message={message.text} />
                        </div>
                        <div className="form-group">
                            <Button btnStyle="primary" customClass="c3-primary" type="submit" disabled={!canSubmit} >
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
            </>
        )
    }
}

export default ContactForm